@import './variables.css';

/* ++++++++++++++++++++++++++++++ */
/* HERO SECTION */
/* ++++++++++++++++++++++++++++++ */

.section-hero {
  padding: 4.8rem 0 9.6rem 0;
  background-color: var(--color-secondary);
  background: var(--linear-gradient-primary--top);
  color: var(--color-font-primary);
}

.hero {
  max-width: 130rem;
  /* center hero section*/
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9.6rem;
  align-items: center;
}

.hero-img {
  width: 100%;
  border-radius: 24px;
  box-shadow: var(--shadow--primary);
}

.hero-description {
  font-size: 2rem;
  line-height: 1.35;
  margin-bottom: 3.2rem;
}

.hero-description:last-child {
  margin-bottom: 4.8rem;
}

/* ++++++++++++++++++++++++++++++ */
/* HEADER SECTION */
/* ++++++++++++++++++++++++++++++ */

.section-essentials {
  padding: 4.8rem 0 3.2rem 0;
}

.essential-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3.2rem 2.4rem;
  background-color: var(--color-secondary-tint);
  border-radius: 24px;
  transition: all 0.4s;
}

.essential-text-box:hover {
  transform: scale(1.1);
}

.essential-heading {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 3.2rem;
}

.essential-icon {
  color: var(--color--logo-icon-primary);
  width: 3.6rem;
  height: 3.6rem;
  padding: 1.2rem;
  background-color: #fff;
  border-radius: 50%;
}

.essential-description {
  font-size: 1.8rem;
  line-height: 1.35;
  margin-bottom: 3.6rem;
}

.essential-img-box {
  min-width: 37rem;
}

.essential-img {
  display: block;
  width: 100%;
  border-radius: 24px;
  box-shadow: var(--shadow--primary);
}

/* ++++++++++++++++++++++++++++++ */
/* GALLERY SECTION */
/* ++++++++++++++++++++++++++++++ */

.section-gallery {
  padding: 12.8rem 0;
}

.gallery-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
  overflow: hidden;
  border-radius: 24px;
}

.gallery-img-block {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.style-1 {
  grid-template-rows: 0.5fr 1fr 0.5fr;
}

.style-2 {
  grid-template-rows: 1fr 0.5fr 1fr;
}

.img-pos-mid-wide {
  grid-column: 1 / -1;
  height: 100%;
}

.img-pos--edge--wide {
  grid-column: 1 / -1;
}

.gallery-img-block img {
  width: 100%;
}

/* ++++++++++++++++++++++++++++++ */
/* TESTIMONIAL SECTION */
/* ++++++++++++++++++++++++++++++ */

.section-testimonials {
  padding: 4.8rem 0 3.2rem 0;
  background-color: var(--color-secondary);
}

.testimonial-img {
  height: 20rem;
  border-radius: 8px;
  transform: scale(1.5);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
}

.testimonial-text {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 3.2rem;
  color: var(--color-font-quaternary);
}
.testimonial-author {
  font-size: 1.4rem;
  color: var(--color-font-quaternary);
}
.testimonial-job {
  font-size: 1.2rem;
  color: var(--color-font-quaternary);
}

/* ++++++++++++++++++++++++++++++ */
/* PRICING SECTION */
/* ++++++++++++++++++++++++++++++ */

.section-pricing {
  padding: 4.8rem 0 12.8rem 0;
  /* padding: 12.8rem 0; */
}

.pricing-option {
  padding: 4.8rem;
  width: 100%;
  max-width: 36.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 24px;
}

.pricing-header {
  text-align: center;
}

.pricing-title {
  font-size: 2.4rem;
  font-weight: 500;
  color: var(--color-primary);
  margin-bottom: 1.8rem;
}
.pricing-cost {
  font-size: 5.2rem;
  font-weight: 700;
  color: var(--color-font-primary);
  margin-bottom: 1.6rem;
}
.pricing-text {
  font-size: 1.8rem;
  color: var(--color-primary);
  margin-bottom: 1.2rem;
}

/* ++++++++++++++++++++++++++++++ */
/* FEATURES  SECTION */
/* ++++++++++++++++++++++++++++++ */

.feature-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  margin-bottom: 1.6rem;
}

.feature-icon {
  color: var(--color--logo-icon-primary);
  height: 3.2rem;
  width: 3.2rem;
  background-color: var(--color--logo-icon-secondary);
  padding: 1.6rem;
  border-radius: 50%;
}
.feature-title {
  font-size: 2.4rem;
  color: var(--color-font-secondary);
  font-weight: 700;
}
.feature-text {
  font-size: 1.8rem;
  line-height: 1.8;
  text-align: center;
}
